import { defineStore } from 'pinia'

import { IMemberGroup, MemberGroupApi } from '@/api/member-groups'
import { MEMBER_GROUP } from '@/store/keys'

const getInitialState = () => {
  return {
    updatedAt: 0,
    memberGroupId: -1,
    memberGroup: null as IMemberGroup.MemberGroup | null,
  }
}

export const useMemberGroupStore = defineStore(MEMBER_GROUP, {
  state: () => getInitialState(),
  getters: {
    serviceTypes(state): IMemberGroup.ServiceType[] {
      return state.memberGroup?.serviceTypes ?? []
    },

    credits(): IMemberGroup.CreditsByType {
      const info = this.memberGroup
      const totalRemain = info?.remainingCredits ?? 0
      const freeRemain = info?.freeRemainingCredits ?? 0
      const paidRemain = info?.paidRemainingCredits ?? 0
      const team = info?.team || null

      return {
        totalRemain,
        freeRemain,
        paidRemain,
        team
      }
    },

    /**
     * 다른 지점(타지점) 예약 가능여부
     *
     * @deprecated
     */
    isAccessibleNonContractedBranch(): boolean {
      return this.serviceTypes.every((serviceType) => {
        return serviceType === 'fastfive'
      })
    },
  },
  actions: {
    checkMemberGroupId(memberGroupId: number) {
      if (this.memberGroupId !== memberGroupId) {
        this.updatedAt = 0
        this.memberGroupId = memberGroupId
      }
    },
    /**
     * 1초이내에 여러번 호출되면 true를 반환함
     */
    checkMultipleCall() {
      const now = Date.now()
      const diff = now - this.updatedAt

      if (diff > 1000) {
        this.updatedAt = now
        return false
      }

      return true
    },
    async load(memberGroupId: number) {
      this.checkMemberGroupId(memberGroupId)

      if (this.checkMultipleCall()) {
        return this.memberGroup
      }

      const res = await MemberGroupApi.getOne(memberGroupId)
      const info = res.memberGroup

      this.memberGroup = info ?? null

      return this.memberGroup
    },
  },
})
