import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 24 24",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M5 5L19 19",
      stroke: _ctx.stroke,
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, 8, _hoisted_2),
    _createElementVNode("path", {
      d: "M19 5L5 19",
      stroke: _ctx.stroke,
      "stroke-width": "1.5",
      "stroke-linecap": "round"
    }, null, 8, _hoisted_3)
  ], 8, _hoisted_1))
}