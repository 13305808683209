<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.break]: !!breakPoint,
    }"
  >
    <div :class="$style.scrollView">
      <div :class="$style.box">
        <div
          v-if="hasHeader"
          :class="$style.header"
        >
          <div
            v-if="hasClose"
            :class="$style.close"
            @click="onCloseClick"
          >
            <IconClose />
          </div>
          <div
            v-if="title"
            :class="$style.title"
          >
            {{ title }}
          </div>
          <div
            v-if="subtitle"
            :class="$style.subtitle"
          >
            {{ subtitle }}
          </div>
        </div>

        <SimpleBar
          :class="{
            [$style.body]: true,
            [$style.scrollable]: bodyScrollable,
          }"
        >
          <slot :bodyStyle="$style.bodyStyle" />
        </SimpleBar>

        <div
          v-if="hasFooter"
          :class="$style.footer"
        >
          <div
            v-if="btns"
            :class="$style.btns"
          >
            <template
              v-for="(btn, key) in btns"
              :key="key"
            >
              <Button
                :class="$style.btn"
                frame="flexable"
                type="button"
                size="large"
                :theme="btn.theme"
                :disabled="btn.disabled"
                @click="onBtnClick(btn.onClick)"
              >
                {{ btn.text }}
              </Button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div :class="$style.dimmed" />
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
} from 'vue'

import SimpleBar from '@/components/v2/SimpleBar.vue'
import Button from '@/components/v2/button/Button.vue'
import IconClose from '@/components/v2/icon/IconClose.vue'

import { ModalV2 } from '@/interfaces/components/v2/modal'
import { withPx } from '@/libs/css'
import { useGlobalStore } from '@/store/modules/global'
import Header from "@/containers/layout/Header.vue";

export default defineComponent({
  components: {
    Header,
    Button,
    IconClose,
    SimpleBar,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    hasClose: {
      type: Boolean,
      default: false,
    },
    btns: {
      type: Array as PropType<ModalV2.Props.Btns>,
      default: () => [],
    },
    width: {
      type: String,
      default: '540px',
    },
    breakPoint: {
      type: Number,
    },
    bodyScrollable: {
      type: Boolean,
      default: false,
    },
    bodyHeight: {
      type: String,
      default: 'auto',
    },
    noControlScrollBar: {
      type: Boolean,
      default: false,
    },
    onShow: Function,
    onHide: Function,
  },
  emits: {
    'update:isShow': (val: boolean) => typeof val === 'boolean',
    'click:close': () => true,
  },
  setup(props, { emit }) {
    const globalStore = useGlobalStore()

    const hasHeader = computed(() => {
      return !!props.title || !!props.subtitle || props.hasClose
    })

    const hasFooter = computed(() => {
      return props.btns.length > 0
    })

    const cssVars = computed(() => {
      return {
        width: props.width,
        breakPoint: withPx(props.breakPoint) || 'auto',
        bodyHeight: props.bodyHeight,
      }
    })

    onMounted(() => {
      if (!props.noControlScrollBar) {
        globalStore.enableScrollBar(false)
      }
    })

    onBeforeUnmount(() => {
      if (!props.noControlScrollBar) {
        globalStore.enableScrollBar()
      }
    })

    /**
     * @public
     */
    function show() {
      emit('update:isShow', true)
    }

    /**
     * @public
     */
    function hide() {
      emit('update:isShow', false)
    }

    function onCloseClick() {
      emit('click:close')
      hide()
    }

    function onBtnClick(callback?: ModalV2.BtnClickCallback) {
      callback?.(hide)
    }

    return {
      hasHeader,
      hasFooter,
      cssVars,
      show,
      hide,
      onCloseClick,
      onBtnClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  position: fixed;
  z-index: 105;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  height: 100vh;

  .scrollView {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    padding: 32px;

    .box {
      position: relative;
      z-index: 1;
      background-color: $f-primary-white;
      border-radius: 16px;
      width: v-bind('cssVars.width');
      padding: 32px;
      overflow: hidden;

      .header {
        position: relative;
        margin-bottom: 24px;

        .title {
          @include font_v2('ko', 18px, 700);

          padding-right: 32px;
          color: $f-primary-black;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .subtitle {
          @include font_v2('ko', 15px, 400);
          color: $f-primary-black;
          word-break: break-all;
          white-space: pre-line;
          margin-top: 8px;
        }

        .close {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      .body {
        height: v-bind('cssVars.bodyHeight');

        &.scrollable {
          margin: 0 -32px -32px;
          padding: 0 32px 32px;
        }

        .bodyStyle {
          margin-top: 16px;
        }
      }

      .footer {
        margin-top: 24px;

        .btns {
          text-align: right;

          .btn {
            margin-right: 10px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  &.break {
    width: 100vw;

    .scrollView {
      min-width: v-bind('cssVars.breakPoint');
    }
  }
}

.dimmed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 104;
  background-color: $f-trans-black-70;
  height: 100vh;
}
</style>
