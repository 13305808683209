<template>
  <modal-component
    :is-hard-rendering="isHardRendering"
    :is-show="isShow"
  >
    <div class="modal-content">
      <div class="modal-body">
        <div
          v-if="!!title"
          class="title"
        >
          {{ title }}
        </div>
        <div
          v-if="!!message"
          v-html="message"
          class="description"
        />
      </div>
      <div class="button-area">
        <div class="button-submit">
          <Button
            :size="'large'"
            @click="onSubmit"
          >
            {{buttonText}}
          </Button>
        </div>
      </div>
    </div>
  </modal-component>
</template>

<script>
import { defineComponent } from 'vue'

import ModalComponent from './index.vue'
import Button from '@/components/v2/button/Button.vue'

export default defineComponent({
  name: 'Alert',
  components: {
    ModalComponent,
    Button
  },
  props: {
    isHardRendering: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    title: String,
    message: String,
    buttonText: {
      type: String,
      default: '확인',
    },
  },
  emits: ['confirm'],
  setup(props, { emit }) {
    return {
      onSubmit() {
        emit('confirm')
      },
    }
  },
})
</script>

<style lang="scss" scoped>
.modal-content {
  width: 340px;
  .modal-body {
    padding: 32px 32px 24px 32px;
  }
  .title {
    white-space: pre-line;
    @include font_v2('ko', 18px, 700);
    color: $primary-black;
    padding-bottom: 8px;
  }
  .description {
    white-space: pre-line;
    word-break: break-all;
    @include font_v2('ko', 15px, 400);
    color: $primary-black;
  }
  .button-area {
    display: flex;
    justify-content: flex-end;
    padding: 0 32px 32px 32px;

    .button-submit {
      margin-left: 12px;
    }
  }
}
</style>
