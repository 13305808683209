import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, Transition as _Transition, Teleport as _Teleport, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/v2/icon/20px/reservation_fill.svg'
import _imports_1 from '@/assets/images/v2/icon/16px/notice.svg'


const _hoisted_1 = ["onClick", "onMouseenter", "onMousemove"]
const _hoisted_2 = { class: "tw-mb-2 tw-mt-4 tw-flex tw-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "tw-mr-1"
}
const _hoisted_4 = ["src", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_SimpleBar = _resolveComponent("SimpleBar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.container)
    }, [
      _createVNode(_component_SimpleBar, {
        ref: "wrapperRef",
        class: _normalizeClass(_ctx.$style.wrapper)
      }, {
        default: _withCtx(() => [
          (_ctx.searchable)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass({
          [_ctx.$style.item]: true,
          [_ctx.$style.searchbar]: true,
        })
              }, [
                _createVNode(_component_Search, {
                  ref: "searchRef",
                  modelValue: _ctx.searchValue,
                  placeholder: _ctx.searchInputPlaceholder,
                  width: "100%",
                  "onUpdate:modelValue": _ctx.updateSearchValue
                }, null, 8, ["modelValue", "placeholder", "onUpdate:modelValue"])
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.noticeText)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass({
          [_ctx.$style.item]: true,
          [_ctx.$style.noticeText]: true,
        })
              }, [
                _createElementVNode("img", {
                  class: _normalizeClass(_ctx.$style.icon),
                  src: _imports_0,
                  width: "16",
                  height: "16"
                }, null, 2),
                _createTextVNode(" " + _toDisplayString(_ctx.noticeText), 1)
              ], 2))
            : _createCommentVNode("", true),
          (_ctx.searchable && _ctx.state.searchValue && !_ctx.filteredOptions.length)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass([_ctx.$style.item, _ctx.$style.notFound])
              }, _toDisplayString('검색 결과가 없습니다.\n다른 검색어를 입력해 주세요.'), 2))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 3 }, _renderList(_ctx.filteredOptions, (item, key) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: key,
                  class: _normalizeClass({
            [_ctx.$style.item]: true,
            [_ctx.$style.header]: _ctx.isHeaderItem(item),
            [_ctx.$style.selected]: _ctx.isSelectedItem(item),
            [_ctx.$style.withSearchbar]: _ctx.searchable && key === 0,
            [_ctx.$style.withNoticeText]: _ctx.noticeText && key === 0,
          }),
                  onClick: () => _ctx.onItemClick(item),
                  onMouseenter: (ev) => _ctx.showPreviewLayer(ev, item),
                  onMousemove: (ev) => item.type === 'item' && ev.stopPropagation()
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style.label)
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(_ctx.$style.labelText)
                    }, _toDisplayString(item.label), 3),
                    (item.notice)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(_ctx.$style.notice)
                        }, [
                          _createElementVNode("img", {
                            class: _normalizeClass(_ctx.$style.icon),
                            src: _imports_1,
                            width: "16",
                            height: "16"
                          }, null, 2),
                          _createTextVNode(" " + _toDisplayString(item.notice), 1)
                        ], 2))
                      : _createCommentVNode("", true)
                  ], 2),
                  (item.subText)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass({
              [_ctx.$style.subText]: true,
              [_ctx.$style.showAllText]: _ctx.showAllSubtext,
            })
                      }, _toDisplayString(item.subText), 3))
                    : _createCommentVNode("", true)
                ], 42, _hoisted_1))
              }), 128))
        ]),
        _: 1
      }, 8, ["class"])
    ], 2),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.previewLayerContainer)
      }, [
        _createVNode(_Transition, {
          enterActiveClass: _ctx.$style.ActiveTransition,
          enterFromClass: _ctx.$style.hiddenLayer,
          enterToClass: _ctx.$style.shownLayer,
          leaveActiveClass: _ctx.$style.ActiveTransition,
          leaveFromClass: _ctx.$style.shownLayer,
          leaveToClass: _ctx.$style.hiddenLayer
        }, {
          default: _withCtx(() => [
            (_ctx.previewLayerState.isShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([_ctx.$style.interactionArea, 'tw-fixed']),
                  style: _normalizeStyle({
            top: _ctx.withPx(_ctx.previewLayerState.top) || 'auto',
            bottom: _ctx.withPx(_ctx.previewLayerState.bottom) || 'auto',
            left: _ctx.withPx(_ctx.previewLayerState.left) || 'auto',
            right: _ctx.withPx(_ctx.previewLayerState.right) || 'auto',
            'z-index': 101,
          }),
                  onMousemove: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                }, [
                  _createElementVNode("div", {
                    ref: "previewLayerEl",
                    class: _normalizeClass([
              _ctx.$style.previewLayer,
              'tw-w-[360px] tw-rounded-[16px] tw-bg-v2-primary-white tw-p-4',
            ]),
                    style: _normalizeStyle({
              boxShadow: `0 0 16px ${_ctx.hexToRGBA(_ctx.$colors.black, 0.05)}`,
            })
                  }, [
                    _createElementVNode("div", {
                      class: "tw-h-[205px] tw-w-[328px] tw-rounded-[8px] tw-bg-cover tw-bg-center",
                      style: _normalizeStyle({
                backgroundImage: `url(${_ctx.previewLayerValue.imgUrl || require('@/assets/images/placeholder_800w.jpg')})`,
              })
                    }, null, 4),
                    _createElementVNode("div", _hoisted_2, [
                      (_ctx.previewLayerValue.summaryPrefix)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            (_ctx.previewLayerValue.summaryPrefix.type === 'icon')
                              ? (_openBlock(), _createElementBlock("img", {
                                  key: 0,
                                  class: "tw-block",
                                  src: _ctx.previewLayerValue.summaryPrefix.url,
                                  alt: "icon",
                                  width: _ctx.previewLayerValue.summaryPrefix.width,
                                  height: _ctx.previewLayerValue.summaryPrefix.height
                                }, null, 8, _hoisted_4))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: _normalizeClass([_ctx.$style.summaryText, 'tw-text-v2-gray-50'])
                      }, _toDisplayString(_ctx.previewSummaryText), 3)
                    ])
                  ], 6)
                ], 38))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["enterActiveClass", "enterFromClass", "enterToClass", "leaveActiveClass", "leaveFromClass", "leaveToClass"])
      ], 2)
    ]))
  ], 64))
}