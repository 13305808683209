<template>
  <div
    :class="{
      [$style.container]: true,
      [$style.disabled]: disabled,
      [$style.selected]: state.isSelected,
      [$style.hasDesc]: !!desc,
    }"
    @click="onContainerClick"
  >
    <div :class="$style.textWrapper">
      <div :class="$style.title">
        {{ title }}
      </div>

      <template v-if="teamName">
        <div :class="$style.team">
          {{ teamName }}
        </div>
      </template>

      <template v-if="desc">
        <div :class="$style.desc">
          {{ desc }}
        </div>
      </template>
    </div>

    <template v-if="!disabled">
      <Radio
        v-model="state.isSelected"
        readonly
      />
    </template>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent, onBeforeMount, reactive, watch } from 'vue'

import Radio from '@/components/v2/toggle/Radio.vue'

import { DropdownV2 } from '@/interfaces/components/v2/dropdown'

export default defineComponent({
  components: {
    Radio,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
    },
    teamName: {
      type: String as PropType<String | null>,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function as PropType<
        (state: DropdownV2.AccountGroupItem.State) => void
      >,
    },
  },
  emits: {
    'update:selected': (val: boolean) => typeof val === 'boolean',
  },
  setup(props, { emit }) {
    const state = reactive<DropdownV2.AccountGroupItem.State>({
      isSelected: false,
    })

    watch(
      () => props.selected,
      (val) => {
        state.isSelected = val
      },
    )

    onBeforeMount(() => {
      state.isSelected = props.selected
    })

    function onContainerClick() {
      if (props.disabled) {
        return
      }

      if (typeof props.onClick === 'function') {
        props.onClick(state)
        return
      }

      const nextValue = !state.isSelected

      state.isSelected = nextValue
      emit('update:selected', nextValue)
    }

    return {
      state,
      onContainerClick,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  display: flex;
  align-items: center;
  padding: 16px;
  border-radius: 12px;

  .textWrapper {
    flex: 1;
    margin-right: 16px;

    .title {
      @include font_v2('ko', 14px, 400);
      color: $f-gray-45;
      margin-bottom: 4px;
      word-break: break-all;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .team {
      @include font_v2('ko', 12px, 400);
      color: $f-gray-40;
      word-break: break-all;
    }

    .desc {
      @include font_v2('ko', 12px, 400);
      color: $f-gray-60;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &:not(.disabled) {
    cursor: pointer;

    &:hover {
      background-color: $f-trans-white-10;
    }

    &:active {
      background-color: $f-trans-white-20;
    }
  }

  &.selected {
    .textWrapper {
      .title {
        color: $f-primary-white;
        font-weight: 500;
      }
    }
  }
}
</style>
