<template>
  <div :class="$style.container">
    <router-link :to="{ name: hasProfile ? 'Home' : 'SignIn' }">
      <div
        :class="$style.logo"
        @click="$emit('click:logo')"
      >
        <img
          :src="images.logo"
          alt="logo"
        />
      </div>
    </router-link>

    <div
      v-if="!noMenu"
      :class="$style.headerMenu"
    >
      <template v-if="hasProfile && sessionStore.isLogin">
        <SelectBox
          v-model:open="state.isShowProfile"
          :class="$style.item"
          popupPosition="right"
          @update:open="onProfileMenuClick"
        >
          <template v-slot>
            <div :class="[$style.label, $style.ellipsis]">
              {{ memberGroup ? memberGroup.name : '소속된 그룹이 없습니다' }}
            </div>
          </template>
          <template v-slot:popup="{ onInsideClick }">
            <AccountPopup
              :profileThumbnail="user?.imageUrl"
              :username="user?.name"
              :occupation="user?.occupation"
              :email="user?.email"
              :memberGroups="memberGroups"
              :selectedMemberGroupId="selectedMemberGroupId"
              @click="onInsideClick"
              @click:logout="onLogoutClick"
              @click:my-page="onMyPageClick"
              @click:reset-password="onResetPasswordClick"
              @change:member-group="onMemberGroupChange"
              @click:invite="onInvitationClick"
            />
          </template>
        </SelectBox>
      </template>
    </div>

    <Modal
      v-if="state.isShowInviteModal"
      :isShow="state.isShowInviteModal"
      :title="'초대 링크 입력'"
      :subtitle="'관리 멤버에게 받은 초대 링크를 복사하여 아래 입력해 주세요.'"
      :hasFooter="true"
      :btns="[
        {
          text: '취소',
          theme: 'border-gray-35',
          onClick: onInvitationCancel,
        },
        {
          text: '다음',
          theme: 'primary-black',
          disabled: !state.validInvitationCode,
          onClick: onInvitationAccept,
        },
      ]"
    >
      <Input
        :modelValue="state.invitationCode"
        :warningText="
          state.invitationCode.length && !state.validInvitationCode
            ? '올바른 초대 코드를 입력해 주세요.'
            : ''
        "
        :validator="invitationCodeValidator"
        :first-focus="true"
        placeholder="https://fastfive.app.link/invitation?code=abcde23456"
        @validate="(valid: boolean) => (state.validInvitationCode = valid)"
        @update:modelValue="(value: string) => (state.invitationCode = value)"
      />
      <span
        @click="moveFaqInvitationCode"
        :class="$style.questionLink"
      >
        <img
          :src="images.iconQuestion"
          alt="'물음표 아이콘'"
        />
        <span>유효하지 않은 초대링크라고 뜨나요?</span>
      </span>
    </Modal>

    <div v-if="state.isShowJoinGroupModal">
      <suspense>
        <invitation-container
          :invitation-code="state.invitationCode"
          rejectedModalTitle="소속/회사 정보가 다르군요!"
          @complete="onJoinGroupComplete"
          @invalid="
            () => {
              state.isShowJoinGroupModal = false
              state.isShowInviteModal = true
            }
          "
        />
      </suspense>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'

import AccountPopup from '@/components/v2/dropdown/AccountPopup.vue'
import SelectBox from '@/components/v2/dropdown/SelectBox.vue'
import Input from '@/components/v2/inquiry/Input.vue'
import Modal from '@/components/v2/modal/Modal.vue'
import InvitationContainer from '@/containers/user/Invitation.vue'

import { onPopstateEvent } from '@/hooks/on-popstate-event'
import { SessionStore } from '@/interfaces/store/session'
import { closeAllSurvey, resetSurvey } from '@/libs/survey'
import { useSessionStore } from '@/store/modules/session'

const images = {
  logo: require('@/assets/images/logo/logo_fastfive.png'),
  iconQuestion: require('@/assets/images/v2/icon/16px/question-link-blue.svg'),
}

export default defineComponent({
  components: {
    Modal,
    Input,
    InvitationContainer,
    SelectBox,
    AccountPopup,
  },
  props: {
    hasProfile: {
      type: Boolean,
      default: true,
    },
    noMenu: {
      type: Boolean,
      default: false,
    },
  },
  emits: {
    'click:logo': null,
    'click:logout': null,
    'click:my-page': null,
    'click:reset-password': null,
    'click:invitation': null,
    'click:profile-menu': (isShowProfile) => {
      if (typeof isShowProfile === 'boolean') {
        return true
      }
      console.warn('Invalid click:profile-menu event payload!')
      return false
    },
    'change:member-group': null,
  },
  setup(props, { emit }) {
    const router = useRouter()
    const sessionStore = useSessionStore()

    const state = reactive({
      invitationCode: '',
      validInvitationCode: false,
      isShowProfile: false,
      isShowInviteModal: false,
      isShowJoinGroupModal: false,
    })

    const user = computed(() => sessionStore?.user ?? null)

    const selectedBranch = computed(() => sessionStore.selectedBranch)

    const selectedBranchId = computed(() => selectedBranch.value?.id)

    const memberGroup = computed(() => sessionStore?.memberGroup ?? null)

    const memberGroups = computed(() => sessionStore?.memberGroups ?? [])

    const selectedMemberGroupId = computed(() => {
      return memberGroup.value?.id
    })

    onPopstateEvent(() => {
      state.invitationCode = ''
      state.isShowInviteModal = false
      state.isShowJoinGroupModal = false
      state.isShowProfile = false
      state.validInvitationCode = false
    })

    function invitationCodeValidator(v) {
      return !!v?.match(
        /^(?:https?:\/\/fastfive\.app\.link\/invitation\?(?:.+&)?code=)?([abcdefghjklmnpqrstuvwxyz23456789]{10,})(?:&.+)?$/,
      )
    }

    async function onLogoutClick() {
      state.isShowProfile = false

      emit('click:logout')

      try {
        await sessionStore.signOut()
        resetSurvey()
        closeAllSurvey()
      } finally {
        await router.replace({
          name: 'SignIn',
        })
      }
    }

    function onMyPageClick() {
      state.isShowProfile = false

      emit('click:my-page')

      router.push({
        name: 'MyPage',
      })
    }

    function onResetPasswordClick() {
      state.isShowProfile = false

      emit('click:reset-password')

      router.push({
        name: 'ResetPassword',
      })
    }

    function onMemberGroupChange(
      group: SessionStore.State.MemberGroupListItem,
    ) {
      emit('change:member-group')

      sessionStore
        .selectDefaultMemberGroupSetting({
          memberGroupId: group.memberGroupId,
          branchId: selectedBranchId.value,
        })
        .catch(() => {
          // 일반적으로 세션 만료로 인한 오류가 발생
          // 500번대 에러일 수도 있지만 대응하지 않음
        })
        .finally(() => {
          state.isShowProfile = false
        })
    }

    function onInvitationClick() {
      state.isShowProfile = false
      state.isShowInviteModal = true

      emit('click:invitation')
    }

    function onInvitationCancel() {
      state.invitationCode = ''
      state.isShowInviteModal = false
    }

    function onInvitationAccept() {
      state.isShowInviteModal = false
      state.isShowJoinGroupModal = true
    }

    function onJoinGroupComplete(isJoined) {
      if (isJoined) {
        sessionStore
          .initializeSession()
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            state.invitationCode = ''
            state.isShowJoinGroupModal = false
          })
      } else {
        state.invitationCode = ''
        state.isShowJoinGroupModal = false
      }
    }

    function onProfileMenuClick() {
      emit('click:profile-menu', state.isShowProfile)
    }

    function moveFaqInvitationCode() {
      const routeData = router.resolve({
        name: 'FaqDetail',
        params: {
          KnowledgeId: 'Ka05g000001ixqD',
        },
        query: {
          title: '초대 링크 입력 시, 유효하지 않은 초대 링크라고 뜹니다.',
        },
      })
      window.open(routeData.href, '_blank')
    }

    return {
      images,
      sessionStore,
      state,
      user,
      selectedBranch,
      selectedBranchId,
      memberGroup,
      memberGroups,
      selectedMemberGroupId,
      invitationCodeValidator,
      onLogoutClick,
      onMyPageClick,
      onResetPasswordClick,
      onMemberGroupChange,
      onInvitationClick,
      onInvitationCancel,
      onInvitationAccept,
      onJoinGroupComplete,
      onProfileMenuClick,
      moveFaqInvitationCode,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  height: var(--header-height);
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $f-trans-gray-10;
  backdrop-filter: blur(12px);
  border-bottom: 1px solid $f-gray-20;
  z-index: 101;

  .logo {
    display: flex;
    align-items: center;

    img {
      display: block;
      width: 120px;
    }
  }

  .headerMenu {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .item {
      margin-right: 8px;

      .label {
        min-width: 0;

        &.ellipsis {
          max-width: 216px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .icon {
        margin-right: 4px;
      }
    }
  }

  .questionLink {
    @include font_v2('ko', 14px, 400);
    display: inline-flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    color: $f-link-blue;
    margin-top: 16px;
  }
}
</style>
