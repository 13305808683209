<template>
  <div :class="$style.formControl">
    <p
      v-if="label"
      :class="$style.label"
    >
      {{ label }}
      <span
        v-if="isRequired"
        :class="$style.required"
      ></span>
    </p>
    <div :class="[$style.container, { [$style.disabled]: isDisabled }]">
      <slot name="left" />
      <input
        ref="inputElement"
        :class="{ [$style.input]: true, [$style.enFont]: isEnFont }"
        :type="type"
        :disabled="isDisabled"
        :placeholder="placeholder"
        :required="isRequired"
        :value="inputValue"
        :maxlength="maxlength"
        :min="type === 'number' ? min : undefined"
        :max="type === 'number' ? max : undefined"
        @input="onInput"
        @focus="onFocusInput"
        @blur="onBlurInput"
        @keydown="onKeyDown"
      />
      <slot name="right" />
      <button
        v-if="!isDisabled && isDelete && inputValue"
        :class="$style.deleteButton"
      >
        <img
          :src="deleteIcon"
          alt="삭제"
          @click="onResetButton"
        />
      </button>
      <span
        v-if="isCount && maxlength"
        :class="$style.count"
      >
        {{ `${inputValue ? inputValue.length : 0}/${maxlength}` }}
      </span>
    </div>
    <p
      v-if="warningText"
      :class="$style.warningText"
    >
      <img
        :src="warningIcon"
        :class="$style.icon"
        alt="경고"
      />
      {{ warningText }}
    </p>
    <p
      v-if="helperText"
      :class="$style.helperText"
    >
      <img
        :src="helperIcon"
        :class="$style.icon"
        alt="도움말"
      />
      {{ helperText }}
    </p>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  nextTick,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from 'vue'

export default defineComponent({
  props: {
    placeholder: String,
    formatter: Function,
    helperText: String,
    warningText: String,
    label: String,
    isRequired: Boolean,
    validator: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: 'text', // text, password, tel, number 만 가능
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    isCount: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: -Infinity,
    },
    firstFocus: {
      type: Boolean,
      default: false,
    },
    /** input 내부의 font가 en인지 여부 */
    isEnFont: {
      type: Boolean,
      default: false,
    },
    maxlength: [String, Number],
  },
  emits: [
    'update:modelValue',
    'click',
    'reset',
    'focus',
    'blur',
    'keydown',
    'validate',
  ],
  setup(props, { emit }) {
    const inputValue = ref<string>('')
    const inputElement = ref<HTMLInputElement>()
    const helperIcon = require('@/assets/images/v2/icon/24px/notice.svg')
    const deleteIcon = require('@/assets/images/v2/icon/24px/delete.svg')
    const warningIcon = require('@/assets/images/v2/icon/16px/notice_red.svg')

    watch(
      () => props.modelValue,
      () => {
        inputValue.value = props.modelValue
      },
    )

    onBeforeMount(() => {
      inputValue.value = props.modelValue
    })
    onMounted(() => {
      nextTick(() => {
        if (props.firstFocus) {
          focus()
        }
      })
    })

    function focus() {
      // input 포커스 In
      inputElement.value?.focus()
    }

    function blur() {
      // input 포커스 Out
      inputElement.value?.blur()
    }

    function onInput({ target: { value: newValue } }) {
      const value = props.formatter?.(newValue) ?? newValue
      if (props.maxlength && value.length > props.maxlength) {
        return
      }

      // 실시간 감시
      let newVal
      if (props.type === 'number') {
        if (value === '' || value.length === 0) {
          newVal = NaN
        } else {
          newVal = parseFloat(value)
        }
        if (newVal >= props.max) {
          newVal = props.max
        }
        if (newVal <= props.min) {
          newVal = props.min
        }
      } else if (props.type === 'tel') {
        newVal = value.replace(/[^0-9]/g, '')
      } else {
        newVal = value
      }

      inputValue.value = newVal
      emit('update:modelValue', newVal)
      emit('validate', props.validator(newVal))
    }

    function onFocusInput(e) {
      emit('focus', e)
    }

    function onBlurInput(e) {
      emit('blur', e)
    }

    function onKeyDown(ev: KeyboardEvent) {
      if (props.type === 'number' && props.min === 0 && ev.key === '.') {
        ev.preventDefault()
      }

      emit('keydown', ev)
    }

    function onResetButton() {
      inputValue.value = ''
      emit('update:modelValue', '')
      emit('reset')
    }

    return {
      inputElement,
      inputValue,
      helperIcon,
      warningIcon,
      deleteIcon,

      // function
      onInput,
      onFocusInput,
      onBlurInput,
      onKeyDown,
      onResetButton,
      focus,
    }
  },
})
</script>

<style lang="scss" module>
.formControl {
  display: flex;
  flex-direction: column;

  .label {
    width: max-content;
    display: inline-block;
    position: relative;
    margin-bottom: 8px;
    padding-right: 6px;
    color: $f-gray-50;
    @include font_v2('ko', 14px, 400);

    .required {
      width: 4px;
      height: 4px;
      display: inline-block;
      position: absolute;
      top: 2px;
      right: 0;
      border-radius: 4px;
      background-color: $f-secondary-orange;
    }
  }

  > .container {
    height: 48px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border: 1px solid $f-gray-30;
    border-radius: 12px;

    .input {
      all: unset;
      min-width: 0;
      flex: 1;
      box-sizing: border-box;
      @include font_v2('ko', 16px, 400);
      text-align: start;
      border: none;
      background-color: transparent;

      &::placeholder {
        color: $f-gray-45;
      }

      &:disabled {
        color: $f-gray-45;
        background-color: $f-gray-15;
      }

      &.enFont {
        @include font_v2('en', 16px, 400);
      }
    }

    .deleteButton {
      all: unset;
      display: none;
      flex-direction: column;
      justify-content: center;
      padding-left: 8px;
      cursor: pointer;
      z-index: 3;
    }

    .count {
      padding-left: 8px;
      color: $f-primary-black;
      @include font_v2('en', 12px, 500);
    }

    &.disabled {
      color: $f-gray-45;
      background-color: $f-gray-15;
    }

    &:focus-within {
      border: 1px solid $f-primary-black;

      .deleteButton {
        display: flex;
      }
    }
  }

  .helperText,
  .warningText {
    display: flex;
    margin-top: 8px;
    color: $f-gray-40;
    @include font_v2('ko', 12px, 500);

    .icon {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }

  .warningText {
    color: $f-warning-red-100;
  }
}
</style>
