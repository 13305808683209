import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconClose = _resolveComponent("IconClose")!
  const _component_SimpleBar = _resolveComponent("SimpleBar")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass({
      [_ctx.$style.container]: true,
      [_ctx.$style.break]: !!_ctx.breakPoint,
    })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.scrollView)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.$style.box)
        }, [
          (_ctx.hasHeader)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(_ctx.$style.header)
              }, [
                (_ctx.hasClose)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.close),
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCloseClick && _ctx.onCloseClick(...args)))
                    }, [
                      _createVNode(_component_IconClose)
                    ], 2))
                  : _createCommentVNode("", true),
                (_ctx.title)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass(_ctx.$style.title)
                    }, _toDisplayString(_ctx.title), 3))
                  : _createCommentVNode("", true),
                (_ctx.subtitle)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 2,
                      class: _normalizeClass(_ctx.$style.subtitle)
                    }, _toDisplayString(_ctx.subtitle), 3))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true),
          _createVNode(_component_SimpleBar, {
            class: _normalizeClass({
            [_ctx.$style.body]: true,
            [_ctx.$style.scrollable]: _ctx.bodyScrollable,
          })
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {
                bodyStyle: _ctx.$style.bodyStyle
              })
            ]),
            _: 3
          }, 8, ["class"]),
          (_ctx.hasFooter)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style.footer)
              }, [
                (_ctx.btns)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass(_ctx.$style.btns)
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.btns, (btn, key) => {
                        return (_openBlock(), _createBlock(_component_Button, {
                          key: key,
                          class: _normalizeClass(_ctx.$style.btn),
                          frame: "flexable",
                          type: "button",
                          size: "large",
                          theme: btn.theme,
                          disabled: btn.disabled,
                          onClick: ($event: any) => (_ctx.onBtnClick(btn.onClick))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(btn.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["class", "theme", "disabled", "onClick"]))
                      }), 128))
                    ], 2))
                  : _createCommentVNode("", true)
              ], 2))
            : _createCommentVNode("", true)
        ], 2)
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.dimmed)
    }, null, 2)
  ], 64))
}