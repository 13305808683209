import _refiner from 'refiner-js'

import { REFINER_KEY } from '@/env'
import { SessionStore } from '@/interfaces/store/session'
import { getRemainingDaysFromToday } from '@/libs/date'
import { useSessionStore } from '@/store/modules/session'

/**
 * 초기 세팅
 */
export const setSurvey = () => {
  _refiner('setProject', REFINER_KEY)
}

/**
 * 고유한 유저 정보 세팅
 * @param params
 */
export const setSurveyUser = <T>(params: T): void => {
  _refiner('identifyUser', params)
}

/**
 * 로그아웃 시 초기화
 */
export const resetSurvey = () => {
  _refiner('resetUser')
  _refiner('closeForm')
}

/**
 * 모든 설문을 닫기
 */
export const closeAllSurvey = () => {
  _refiner('closeForm')
}

/**
 * [FFIVE-3144] 재걔약 대상 설문조사를 위해 고유한 유저를 세팅하는 조건
 * 1. 로그인된 사용자의 role이 admin | master 이면서
 * 2. 현재 선택된 memberGroup의 contract.maxEndDate(계약 만료일)가 존재하는 경우
 */
export const setSurveyUserForReContract = () => {
  const sessionStore = useSessionStore()
  if (!sessionStore || !sessionStore.isLogin || !sessionStore.user) {
    return
  }
  if (!['admin', 'master'].includes(sessionStore.user?.role || '')) {
    return
  }

  const maxDate = getValidContractEndDate(
    sessionStore.memberGroups,
    sessionStore.memberGroup?.id,
  )

  if (!maxDate) {
    return
  }

  setSurveyUser({
    id: sessionStore.user.memberId,
    role: sessionStore.user.role,
    remainingContractDays: getRemainingDaysFromToday(maxDate),
  })
}

const getValidContractEndDate = (
  memberGroups: SessionStore.State.MemberGroupListItem[],
  memberGroupId: number | undefined,
) => {
  const selectedGroup = memberGroups.find(
    (item) => item.memberGroupId === memberGroupId,
  )
  return selectedGroup ? selectedGroup?.contract?.maxEndDate : null
}
