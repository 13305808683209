import { DeepReadonly, computed, reactive, readonly, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

import { MODE } from '@/env'
import { NavMenu } from '@/interfaces/nav-menu'
import { useSessionStore } from '@/store/modules/session'

/**
 * 25-01-21 배포
 * https://fastfive.atlassian.net/browse/FFIVE-3413
 * 팀관리 탭을 패스트파이브 멤버에게만 노출하도록 설정
 */
const PRODUCTION_ALLOW_MEMBER_GROUP = [1]
const DEV_ALLOW_MEMBER_GROUP = [83]
export const ALLOW_MEMBER_GROUP =
  MODE === 'development'
    ? DEV_ALLOW_MEMBER_GROUP
    : PRODUCTION_ALLOW_MEMBER_GROUP

export const excludeRouteNameSet = new Set<NavMenu.RouteName>(['NotFound'])

export const navMenuOptions: NavMenu.RootItem[] = [
  {
    type: 'root',
    title: '예약',
    componentName: 'IconReservation',
    allowedMemberRoles: [],
    childs: [
      {
        type: 'child',
        routeName: 'Reserve',
        text: '예약하기',
      },
      {
        type: 'child',
        routeName: 'ReservationHistory',
        text: '예약 내역',
      },
    ],
  },
  {
    type: 'root',
    title: '베네핏',
    componentName: 'IconBenefit',
    allowedMemberRoles: [],
    childs: [
      {
        type: 'child',
        routeName: 'Benefits',
        text: '제휴 혜택',
        badge: {
          type: 'component',
          name: 'ImgBadgePlus',
          attrs: {
            width: 24,
            height: 14,
          },
        },
      },
      // {
      //   type: 'child',
      //   routeName: 'BenefitEats',
      //   text: '패파 맛집',
      // },
    ],
  },
  {
    type: 'root',
    title: '그룹 관리',
    componentName: 'IconGroup',
    allowedMemberRoles: ['master', 'admin'],
    disallowGuestGroup: true,
    childs: [
      {
        type: 'child',
        routeName: 'Member',
        text: '멤버 관리',
      },
      {
        type: 'child',
        routeName: 'GroupTeam',
        text: '팀 관리',
        isAllowSpecificMemberGroup: true,
      },
      {
        type: 'child',
        routeName: 'GroupCredit',
        text: '크레딧 관리',
      },
    ],
  },
]

export const useNavMenu = () => {
  const route = useRoute()
  const sessionStore = useSessionStore()

  const memberRole = computed(() => sessionStore.memberRoleForGroup)
  const memberGroupId = computed(() => sessionStore.memberGroup?.id)

  const state = reactive({
    lastRouteName: null as NavMenu.RouteName | null,
    menu: navMenuOptions.map((rootMenu) => ({
      ...rootMenu,
      childs: rootMenu.childs.map((childMenu) => ({
        ...childMenu,
        root: rootMenu,
      })),
    })),
  })

  watchEffect(() => {
    if (!route.name) {
      state.lastRouteName = null
      return
    }

    if (excludeRouteNameSet.has(route.name)) {
      return
    }

    state.lastRouteName = route.name
  })

  function isVisiable(item: NavMenu.Item | DeepReadonly<NavMenu.Item>) {
    switch (item.type) {
      case 'root': {
        const allowedMemberRoles = item?.allowedMemberRoles ?? []
        const disallowGuestGroup = item?.disallowGuestGroup ?? false
        const role = memberRole.value ?? 'guest'

        if (
          allowedMemberRoles.length > 0 &&
          !allowedMemberRoles.includes(role)
        ) {
          return false
        }

        if (disallowGuestGroup && sessionStore.isGuestOrLeaver) {
          return false
        }

        return true
      }

      case 'child': {
        if (item.isAllowSpecificMemberGroup) {
          if (
            memberGroupId.value &&
            !ALLOW_MEMBER_GROUP.includes(memberGroupId.value)
          ) {
            return false
          }
        }

        if (item.root) {
          return isVisiable(item.root)
        }

        break
      }
    }

    return false
  }

  function isActive(item: NavMenu.Item | DeepReadonly<NavMenu.Item>) {
    switch (item.type) {
      case 'root': {
        return item.childs.some((childMenu) => isActive(childMenu))
      }

      case 'child': {
        const currentRouteName =
          route.meta?.aliasRouteNameForMenu || state.lastRouteName || route.name

        return currentRouteName === item.routeName
      }
    }
  }

  return {
    state: readonly(state),
    isVisiable,
    isActive,
  }
}
