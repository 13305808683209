<template>
  <div
    class="modal"
    v-if="!isHardRendering || isShow"
    :class="{ visible: isShow }"
    :id="id"
  >
    <div class="modal-layer"></div>
    <div class="modal-container">
      <div class="modal-box">
        <slot :isShow="isShow"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    isHardRendering: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-container {
    display: none;
  }
  &.visible {
    position: relative;
    z-index: 106;
    .modal-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      .modal-box {
        background-color: $white;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
      }
    }
    .modal-layer {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      background: $f-trans-black-70;
      z-index: -1;
    }
  }
}
</style>
