import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Radio = _resolveComponent("Radio")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [_ctx.$style.container]: true,
      [_ctx.$style.disabled]: _ctx.disabled,
      [_ctx.$style.selected]: _ctx.state.isSelected,
      [_ctx.$style.hasDesc]: !!_ctx.desc,
    }),
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onContainerClick && _ctx.onContainerClick(...args)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.textWrapper)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.title)
      }, _toDisplayString(_ctx.title), 3),
      (_ctx.teamName)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.team)
          }, _toDisplayString(_ctx.teamName), 3))
        : _createCommentVNode("", true),
      (_ctx.desc)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.desc)
          }, _toDisplayString(_ctx.desc), 3))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.disabled)
      ? (_openBlock(), _createBlock(_component_Radio, {
          key: 0,
          modelValue: _ctx.state.isSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.isSelected) = $event)),
          readonly: ""
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true)
  ], 2))
}