import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Thumbnail = _resolveComponent("Thumbnail")!
  const _component_IconLogout = _resolveComponent("IconLogout")!
  const _component_Button = _resolveComponent("Button")!
  const _component_IconWork = _resolveComponent("IconWork")!
  const _component_IconEmail = _resolveComponent("IconEmail")!
  const _component_AccountGroupItem = _resolveComponent("AccountGroupItem")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.container)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.header)
    }, [
      _createVNode(_component_Thumbnail, {
        class: _normalizeClass(_ctx.$style.thumbnail),
        src: _ctx.profileThumbnail,
        size: "small",
        radius: "50%",
        border: ""
      }, null, 8, ["class", "src"]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.$style.logout)
      }, [
        _createVNode(_component_Button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click:logout')))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconLogout, {
              class: _normalizeClass(_ctx.$style.icon)
            }, null, 8, ["class"]),
            _cache[4] || (_cache[4] = _createElementVNode("span", null, "로그아웃", -1))
          ]),
          _: 1
        })
      ], 2),
      (_ctx.username)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(_ctx.$style.username)
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.text)
            }, _toDisplayString(_ctx.username), 3)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.occupation)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.occupation)
          }, [
            _createVNode(_component_IconWork, {
              class: _normalizeClass(_ctx.$style.icon),
              color: _ctx.$colorsV2['f-gray-70']
            }, null, 8, ["class", "color"]),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.text)
            }, _toDisplayString(_ctx.occupation), 3)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.email)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(_ctx.$style.email)
          }, [
            _createVNode(_component_IconEmail, {
              class: _normalizeClass(_ctx.$style.icon),
              color: _ctx.$colorsV2['f-gray-70']
            }, null, 8, ["class", "color"]),
            _createElementVNode("div", {
              class: _normalizeClass(_ctx.$style.text)
            }, _toDisplayString(_ctx.email), 3)
          ], 2))
        : _createCommentVNode("", true)
    ], 2),
    _cache[8] || (_cache[8] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.btns)
    }, [
      _createVNode(_component_Button, {
        class: _normalizeClass(_ctx.$style.btn),
        theme: "gray-90",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click:my-page')))
      }, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createElementVNode("span", { class: "font-weight-normal" }, "내계정 관리", -1)
        ])),
        _: 1
      }, 8, ["class"]),
      _createVNode(_component_Button, {
        class: _normalizeClass(_ctx.$style.btn),
        theme: "gray-90",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click:reset-password')))
      }, {
        default: _withCtx(() => _cache[6] || (_cache[6] = [
          _createElementVNode("span", { class: "font-weight-normal" }, "비밀번호 변경", -1)
        ])),
        _: 1
      }, 8, ["class"])
    ], 2),
    _cache[9] || (_cache[9] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.memberGroups)
    }, [
      (_ctx.memberGroups.length)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.memberGroups, (group, key) => {
            return (_openBlock(), _createBlock(_component_AccountGroupItem, {
              key: key,
              title: group.memberGroupName,
              desc: _ctx.getGroupDesc(group),
              teamName: group.teamName,
              selected: _ctx.isSelectedGroup(group),
              disabled: _ctx.isDisabledGroup(group),
              onClick: () => _ctx.onGroupClick(group)
            }, null, 8, ["title", "desc", "teamName", "selected", "disabled", "onClick"]))
          }), 128))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(_ctx.$style.empty)
          }, "소속된 그룹이 없습니다", 2))
    ], 2),
    _cache[10] || (_cache[10] = _createElementVNode("hr", null, null, -1)),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.$style.footer)
    }, [
      _createVNode(_component_Button, {
        theme: "gray-90",
        size: "large",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('click:invite')))
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [
          _createTextVNode(" 소속그룹 초대링크 직접 입력 ")
        ])),
        _: 1
      })
    ], 2)
  ], 2))
}