<template>
  <ConfirmModal
    v-if="cancellable"
    :isShow="state.isShow"
    :title="title"
    :message="message"
    :confirmButtonText="confirmButtonText"
    :cancelButtonText="cancelButtonText"
    @confirm="onConfirmEvent"
    @cancel="onCancelEvent"
  />
  <AlertModal
    v-else
    :isShow="state.isShow"
    :title="title"
    :message="message"
    :buttonText="confirmButtonText"
    @confirm="onConfirmEvent"
  />
</template>

<script lang="ts">
import { PropType, defineComponent, reactive, watch } from 'vue'

import AlertModal from '@/components/v2/modal/message/Alert.vue'
import ConfirmModal from '@/components/v2/modal/message/Confirm.vue'

export default defineComponent({
  components: {
    ConfirmModal,
    AlertModal,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    cancellable: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: '확인',
    },
    cancelButtonText: {
      type: String,
      default: '취소',
    },
    onConfirm: Function as PropType<(close?: () => void) => void>,
    onCancel: Function as PropType<(close?: () => void) => void>,
  },
  emits: {
    'update:isShow': (val: boolean) => typeof val === 'boolean',
  },
  setup(props, { emit }) {
    const state = reactive({
      isShow: props.isShow,
    })

    watch(
      () => props.isShow,
      (isShow) => {
        state.isShow = isShow
      },
    )

    function close() {
      state.isShow = false
      emit('update:isShow', false)
    }

    function onConfirmEvent() {
      if (!props.onConfirm?.length) {
        close()
      }

      props.onConfirm?.(close)
    }

    function onCancelEvent() {
      if (!props.onCancel?.length) {
        close()
      }

      props.onCancel?.(close)
    }

    return {
      state,
      onConfirmEvent,
      onCancelEvent,
    }
  },
})
</script>
