
import {
  PropType,
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  useCssModule,
  watch,
} from 'vue'

import SimpleBar from '@/components/v2/SimpleBar.vue'
import Search from '@/components/v2/textfield/Search.vue'

import { DropdownV2 } from '@/interfaces/components/v2/dropdown'
import { withPx } from '@/libs/css'
import { thumbnailMaker } from '@/libs/thumbnail'

const __default__ = defineComponent({
  components: {
    SimpleBar,
    Search,
  },
  props: {
    options: {
      type: Array as PropType<DropdownV2.List.Option[]>,
      default: () => [],
    },
    modelValue: {
      type: null as unknown as PropType<DropdownV2.List.ItemOption['value']>,
      default: null,
    },
    width: {
      type: String,
      default: '248px',
    },
    maxHeight: {
      type: Number,
    },
    scrollingAfterMounted: {
      type: Boolean,
      default: false,
    },
    scrollingAfterChanged: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    searchInputPlaceholder: {
      type: String,
      default: '',
    },
    searchInputAutoFocusing: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
    noticeText: {
      type: String,
      default: '',
    },
    /** subText를 생략할 지 여부(생략 시 ... 으로 표기) */
    showAllSubtext: {
      type: Boolean,
      default: false,
    },
    /**
     * 드롭다운 안에 스크롤을 가질 지 여부
     */
    hasInnerScroll: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    'update:modelValue': (_: DropdownV2.List.ItemOption['value']) => true,
    'update:searchValue': (val: string) => typeof val === 'string',
    select: (val: DropdownV2.List.ItemOption) => !!val,
  },
  setup(props, { emit }) {
    const wrapperRef = ref<InstanceType<typeof SimpleBar>>()
    const searchRef = ref<InstanceType<typeof Search>>()
    const previewLayerEl = ref<HTMLDivElement>()

    const $style = useCssModule()

    const state = reactive({
      selectedValue: null as DropdownV2.List.ItemOption['value'],
      wrapperMaxHeight: 0,
      searchValue: '',
    })

    const previewLayerState = reactive({
      isShown: false,
      lastItemEl: null as HTMLLIElement | null,
      lastValue: null as DropdownV2.List.ValueType,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    })

    const wrapperEl = computed(() => wrapperRef.value?.scrollEl)

    const cssVars = computed(() => {
      let maxHeight = ''

      if (!props.hasInnerScroll) {
        maxHeight = 'none'
      } else {
        maxHeight = withPx(state.wrapperMaxHeight) || 'none'
      }

      return {
        width: props.width,
        wrapperMaxHeight: maxHeight,
        fontSize: withPx(props.fontSize) || 'inherit',
      }
    })

    const itemOptionMap = computed(() => {
      const map = new Map<
        DropdownV2.List.ValueType,
        DropdownV2.List.ItemOption
      >()

      props.options.forEach((row) => {
        if (row.type !== 'item') return
        map.set(row.value, row)
      })

      return map
    })

    const previewSummaryText = computed(() => {
      const subway = previewLayerValue.value.subwayName
      const summary = previewLayerValue.value.summary

      if (subway && summary) {
        return `${subway}역 ${previewLayerValue.value.summary}`
      }
      if (summary) {
        return summary
      }
      return '준비중입니다.'
    })

    const previewLayerValue = computed<DropdownV2.PreviewLayer>(() => {
      const option = itemOptionMap.value.get(previewLayerState.lastValue)

      const imgUrl = thumbnailMaker.fromServerUrl({
        url: option?.preview?.imgUrl,
        width: 656,
        height: 410,
      })

      return {
        position: option?.preview?.position || 'right',
        imgUrl,
        summary: option?.preview?.summary || null,
        summaryPrefix: option?.preview?.summaryPrefix || null,
        subwayName: option?.preview?.subwayName || null,
      }
    })

    const filteredOptions = computed(() => {
      const nextOptions: DropdownV2.List.Option[] = []
      let lastHeader = null as DropdownV2.List.HeaderOption | null
      const optionMapByHeader = new Map<
        typeof lastHeader,
        DropdownV2.List.ItemOption[]
      >()

      props.options.forEach((option) => {
        switch (option.type) {
          case 'header':
            lastHeader = option
            break

          case 'item':
            if (
              !state.searchValue ||
              option.label.includes(state.searchValue) ||
              option.subText?.includes(state.searchValue) ||
              option.preview?.summary?.includes(state.searchValue)
            ) {
              const arr = optionMapByHeader.get(lastHeader) || []
              optionMapByHeader.set(lastHeader, arr.concat(option))
            }
            break
        }
      })

      optionMapByHeader.forEach((items, header) => {
        if (!items.length) return

        if (header) {
          nextOptions.push(header)
        }

        nextOptions.push(...items)
      })

      return nextOptions
    })

    watch(
      () => props.modelValue,
      (value) => {
        state.selectedValue = value

        useScrollingAfterChanged()
      },
    )

    watch(
      () => props.searchValue,
      (value) => {
        state.searchValue = value
      },
      {
        immediate: true,
      },
    )

    onBeforeMount(() => {
      state.selectedValue = props.modelValue
    })

    onMounted(() => {
      window.addEventListener('resize', globalResizeListener)
      window.addEventListener('mousemove', hidePreviewLayer)
      window.addEventListener('scroll', calcPreviewLayerPosition)

      calcDropdownContentMaxHeight()

      useScrollingAfterMounted()

      if (props.searchable && props.searchInputAutoFocusing) {
        searchRef.value?.focus()
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', globalResizeListener)
      window.removeEventListener('mousemove', hidePreviewLayer)
      window.removeEventListener('scroll', calcPreviewLayerPosition)
    })

    function onItemClick(item: DropdownV2.List.Option) {
      if (item.type === 'header') {
        return
      }

      state.selectedValue = item.value

      emit('update:modelValue', item.value)
      emit('select', item)
    }

    function isHeaderItem(item: DropdownV2.List.Option) {
      return item.type === 'header'
    }

    function isSelectedItem(item: DropdownV2.List.Option) {
      if (item.type === 'header') {
        return
      }

      return item.value === state.selectedValue
    }

    function calcDropdownContentMaxHeight() {
      const bottomFreeSpace = 40
      const el = wrapperEl.value
      let offsetTop = 0
      let maxHeight = window.innerHeight - bottomFreeSpace

      if (!el) {
        state.wrapperMaxHeight = 0
        return
      }

      offsetTop += el.getBoundingClientRect().top

      maxHeight -= offsetTop

      if (maxHeight <= 0) {
        state.wrapperMaxHeight = 0
        return
      }

      if (props.maxHeight && props.maxHeight < maxHeight) {
        maxHeight = props.maxHeight
      }

      state.wrapperMaxHeight = Math.round(maxHeight)
    }

    function globalResizeListener() {
      calcDropdownContentMaxHeight()
    }

    function useScrollingAfterMounted() {
      if (props.scrollingAfterMounted) {
        scrollToSelectedItem()
      }
    }

    function useScrollingAfterChanged() {
      if (props.scrollingAfterChanged) {
        scrollToSelectedItem()
      }
    }

    async function scrollToSelectedItem() {
      await nextTick()

      const el = wrapperEl.value

      if (!el) return

      const target = el
        .getElementsByClassName($style.selected)
        .item(0) as HTMLElement | null

      if (typeof target?.offsetTop === 'number') {
        el.scrollTop = target.offsetTop
      } else {
        target?.scrollIntoView()
      }
    }

    function showPreviewLayer(ev: MouseEvent, item: DropdownV2.List.Option) {
      const itemEl = ev.target as HTMLLIElement | null

      if (
        !itemEl ||
        item.type !== 'item' ||
        !item.preview
        //
      ) {
        return
      }

      previewLayerState.isShown = true
      previewLayerState.lastItemEl = itemEl
      previewLayerState.lastValue = item.value

      calcPreviewLayerPosition()
    }

    function calcPreviewLayerPosition() {
      const option = itemOptionMap.value.get(previewLayerState.lastValue)
      const itemElRect = previewLayerState.lastItemEl?.getBoundingClientRect()
      const scrollElRect = wrapperEl.value?.getBoundingClientRect()

      if (!option?.preview || !itemElRect || !scrollElRect) return

      previewLayerState.top = itemElRect.top
      previewLayerState.bottom = 0

      switch (option.preview.position) {
        case 'left':
          previewLayerState.left = 0
          previewLayerState.right = window.innerWidth - itemElRect.left
          break

        case 'right':
          previewLayerState.left = itemElRect.right
          previewLayerState.right = 0
          break
      }

      requestAnimationFrame(() => {
        const layerEl = previewLayerEl.value
        const layerElRect = layerEl?.getBoundingClientRect()

        if (layerElRect && scrollElRect.bottom < layerElRect.bottom) {
          previewLayerState.top = 0
          previewLayerState.bottom = window.innerHeight - scrollElRect.bottom
        }
      })
    }

    function hidePreviewLayer() {
      previewLayerState.isShown = false
    }

    function updateSearchValue(val: string) {
      state.searchValue = val
      emit('update:searchValue', val)
    }

    return {
      // ref
      wrapperRef,
      searchRef,
      previewLayerEl,

      // state
      state,
      previewLayerState,

      // computed
      wrapperEl,
      cssVars,
      previewLayerValue,
      filteredOptions,
      previewSummaryText,

      // fn
      onItemClick,
      isHeaderItem,
      isSelectedItem,
      showPreviewLayer,
      hidePreviewLayer,
      updateSearchValue,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "4dcb4857": (_ctx.cssVars.width),
  "1834e927": (_ctx.cssVars.wrapperMaxHeight),
  "63b130bf": (_ctx.cssVars.fontSize)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__