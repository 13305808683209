<template>
  <Modal
    :width="'340px'"
    :title="'소속/회사'"
    :subtitle="'아래 소속 그룹으로 승인 요청을 하시겠어요?'"
    :btns="[
      {
        theme: 'border-gray-35',
        text: '다음에',
        onClick: onReject,
      },
      {
        theme: 'primary-black',
        disabled: !memberGroup,
        text: '승인 요청',
        onClick: onAccept,
      },
    ]"
  >
    <div class="group">
      <div class="group-title">
        <img
          :src="images.iconBriefCase"
          alt="'물음표 아이콘'"
        />
        <p>소속 그룹</p>
      </div>
      <div class="group-text">
        {{ memberGroup?.name ?? '(정보 없음)' }}
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/v2/modal/Modal.vue'

const images = {
  iconBriefCase: require('@/assets/images/v2/icon/16px/briefcase.svg'),
}

export default {
  components: {
    Modal,
  },
  props: {
    memberGroup: Object,
  },
  emits: ['accept', 'reject'],
  setup(props, { emit }) {
    return {
      images,
      onAccept() {
        emit('accept')
      },
      onReject() {
        emit('reject')
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.group {
  border-radius: 12px;
  background: $f-gray-15;
  display: flex;
  width: 276px;
  padding: 16px;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  &-title {
    @include font_v2('ko', 12px, 500);
    display: flex;
    gap: 4px;
    color: $f-gray-50;
  }

  &-text {
    text-align: center;
    word-break: break-all;
    @include font_v2('ko', 18px, 500);
  }
}
</style>