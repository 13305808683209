import { RouteRecordRaw } from 'vue-router'

import Layout from '@/views/Layout.vue'
import NotFound from '@/views/error/NotFound.vue'

import { fromKST } from '@/libs/date'
import { setRedirectUrl } from '@/libs/storage/helpers/redirect-url'
import { $texts } from '@/libs/texts'
import { useSessionStore } from '@/store/modules/session'

export const routes: RouteRecordRaw[] = [
  // 로그인
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('@/views/user/SignIn.vue'),
    props: (route) => {
      const invitationCode =
        String(route.query?.invitationCode || '') || undefined
      const errorMessage = String(route.query?.errorMessage || '') || undefined

      return {
        invitationCode,
        errorMessage,
      }
    },
  },

  // 회원가입
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('@/views/user/SignUp.vue'),
    props: (route) => {
      const invitationCode =
        String(route.query?.invitationCode || '') || undefined
      return {
        invitationCode,
      }
    },
  },

  // 비밀번호 변경
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/user/ResetPassword.vue'),
  },

  // 초대
  {
    path: '/invitation',
    name: 'Invitation',
    component: () => import('@/views/user/Invitation.vue'),
  },

  // 비로그인 초대링크 접근
  {
    path: '/first-invitation',
    name: 'NonLoginInvitation',
    component: () => import('@/views/user/NonLoginInvitation.vue'),
    props: (route) => {
      const invitationCode =
        String(route.query?.invitationCode || '') || undefined
      return {
        invitationCode,
      }
    },
  },

  // 오픈형 문의페이지
  {
    path: '/workanywhere/inquiry',
    name: 'CreateInquiryWorkAnywhere',
    component: () => import('@/views/inquiry/WorkAnywhere.vue'),
    meta: {
      isAccessibleFromMobile: true,
    },
    props: (route) => {
      const query = route.query
      const serviceArea = String(query?.ServiceArea || '') || undefined
      const AccountName = String(query?.AccountName || '') || undefined
      const ContactName = String(query?.ContactName || '') || undefined
      const ContactEmail = String(query?.ContactEmail || '') || undefined
      const Phone = String(query?.Phone || '') || undefined

      return {
        serviceArea,
        AccountName,
        ContactName,
        ContactEmail,
        Phone,
      }
    },
  },

  {
    path: '/',
    name: 'Main',
    component: Layout,
    beforeEnter(to, from, next) {
      const sessionStore = useSessionStore()

      if (to.name === 'NotFound') {
        next()
        return
      }

      if (!sessionStore.isLogin) {
        setRedirectUrl(to.fullPath)

        next({
          name: 'SignIn',
          query: {
            errorMessage:
              to.path !== '/' ? $texts.DENIED_ACCESS_TO_NOT_MEMBER : undefined,
          },
        })
        return
      }

      next()
    },
    children: [
      // 홈
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
        meta: {
          screenName: 'home_main',
        },
      },

      // 공지사항
      {
        path: '/notice',
        name: 'Notice',
        component: () => import('@/views/home/Notice.vue'),
        meta: {
          disallowGuestGroup: {
            name: 'Home',
          },
        },
      },

      // 공지사항 상세
      {
        path: '/notice/:noticeId',
        name: 'NoticeDetail',
        component: () => import('@/views/home/NoticeDetail.vue'),
        meta: {
          disallowGuestGroup: {
            name: 'Home',
          },
          usePathKey: true,
        },
        props(to) {
          const noticeId = parseInt(to.params.noticeId as string) || undefined
          const branchId = parseInt(to.query.branchId as string) || undefined

          return {
            noticeId,
            branchId,
          }
        },
        beforeEnter(to, from, next) {
          const noticeId = parseInt(to.params.noticeId as string) || undefined

          if (!noticeId || typeof noticeId !== 'number') {
            next({
              name: 'Notice',
            })
            return
          }

          next()
        },
      },

      // 지점 이용 안내
      {
        path: '/branch-guide/:branchId?',
        name: 'BranchGuide',
        component: () => import('@/views/home/BranchGuide.vue'),
        meta: {
          disallowGuestGroup: {
            name: 'Home',
          },
          usePathKey: true,
        },
        props(to) {
          const branchId = Number(to.params.branchId) || undefined

          return {
            branchId,
          }
        },
      },

      // 자주 묻는 질문
      {
        path: '/faq',
        name: 'Faq',
        component: () => import('@/views/faq/FaqList.vue'),
        meta: {
          disallowGuestGroup: true,
        },
        props(to) {
          const search = String(to.query.search || '')
          const CategoryId =
            to.query.categoryId !== undefined
              ? String(to.query.categoryId)
              : undefined
          const SectionId =
            to.query.sectionId !== undefined
              ? String(to.query.sectionId)
              : undefined
          return {
            SearchKeyword: search,
            CategoryId,
            SectionId,
          }
        },
      },

      // 자주 묻는 질문 상세
      {
        path: '/faq-detail/:KnowledgeId',
        name: 'FaqDetail',
        component: () => import('@/views/faq/FaqDetail.vue'),
        props: true,
      },
      // 1:1 문의 등록
      {
        path: '/inquiry/create',
        name: 'CreateInquiry',
        component: () => import('@/views/inquiry/InquiryForm.vue'),
        props(to) {
          const serviceArea = to.query.ServiceArea
            ? String(to.query.ServiceArea)
            : undefined

          return {
            serviceArea,
          }
        },
      },
      // 1:1 문의 리스트
      {
        path: '/inquiry/list',
        name: 'InquiryList',
        component: () => import('@/views/inquiry/InquiryList.vue'),
      },
      // 1:1 문의 상세
      {
        path: '/inquiry/detail/:caseId',
        name: 'InquiryDetail',
        component: () => import('@/views/inquiry/InquiryDetail.vue'),
        props: true,
        beforeEnter(to, from, next) {
          const caseId = to.params.caseId || undefined

          if (!caseId) {
            next({
              name: 'InquiryList',
            })
            return
          }

          next()
        },
      },

      // 사무공간 휴지통 클리닝 요청
      {
        path: '/inquiry/request-cleaning',
        name: 'InquiryRequestCleaning',
        component: () => import('@/views/inquiry/RequestCleaning.vue'),
        meta: {
          screenName: 'InquiryRequestCleaning',
          disallowGuestGroup: {
            name: 'Home',
          },
        },
      },

      // 패파 생활 시작하기
      {
        path: '/onboarding',
        name: 'Onboarding',
        component: () => import('@/views/home/onboarding/Onboarding.vue'),
        meta: {
          disallowGuestGroup: {
            name: 'Home',
          },
        },
      },

      // 복합기/네트워크 이용가이드
      {
        path: '/onboarding/infra',
        name: 'OnboardingInfra',
        component: () => import('@/views/home/onboarding/OnboardingInfra.vue'),
        meta: {
          disallowGuestGroup: {
            name: 'Home',
          },
        },
      },

      // 공간 예약 안내
      {
        path: '/onboarding/reserve-space',
        name: 'OnboardingReserveSpace',
        component: () =>
          import('@/views/home/onboarding/OnboardingReserveSpace.vue'),
        meta: {
          disallowGuestGroup: {
            name: 'Home',
          },
          disallowServiceTypes: (serviceTypes) => {
            return (
              (serviceTypes.includes('moverse') && {
                route: {
                  name: 'Onboarding',
                },
              }) ||
              false
            )
          },
        },
      },

      // 그룹 및 멤버 관리 가이드
      {
        path: '/onboarding/manage-group',
        name: 'OnboardingManageGroup',
        component: () =>
          import('@/views/home/onboarding/OnboardingManageGroup.vue'),
        meta: {
          disallowGuestGroup: {
            name: 'Home',
          },
        },
      },

      // 모바일 출입카드 가이드
      {
        path: '/onboarding/mobile-card',
        name: 'OnboardingMobileCard',
        component: () =>
          import('@/views/home/onboarding/OnboardingMobileCard.vue'),
        meta: {
          disallowGuestGroup: {
            name: 'Home',
          },
        },
      },

      // 패스트파이브 플러스
      {
        path: '/plus',
        name: 'FastfivePlus',
        component: () => import('@/views/home/FastfivePlus.vue'),
      },

      // 내 계정 관리
      {
        path: '/my-page',
        name: 'MyPage',
        component: () => import('@/views/user/MyPage.vue'),
      },

      // 내 번호 변경
      {
        path: '/change-phone-number',
        name: 'ChangePhoneNumber',
        component: () => import('@/views/user/ChangePhoneNumber.vue'),
      },

      // 탈퇴하기
      {
        path: '/remove-account',
        name: 'RemoveAccount',
        component: () => import('@/views/user/RemoveAccount.vue'),
      },

      // 예약하기
      {
        path: '/reserve/:spaceCategoryId?',
        name: 'Reserve',
        component: () => import('@/views/reservation/Reserve.vue'),
        meta: {
          screenName: 'reservation_main',
          disallowGuestGroup: true,
          hideFooter: true,
        },
        props(route) {
          return {
            spaceCategoryId: Number(route.params.spaceCategoryId) || undefined,
          }
        },
      },

      // 예약내역
      {
        path: '/reservation-history/:type?',
        name: 'ReservationHistory',
        component: () => import('@/views/reservation/History.vue'),
        meta: {
          screenName: 'reservation_history_main',
          disallowGuestGroup: true,
        },
        props(route) {
          const date = fromKST(String(route.query.date))
          let type = route.params.type as string

          if (type !== 'group') {
            type = ''
          }

          return {
            type,
            date: date.isValid() ? date.format('YYYY-MM-DD') : undefined,
          }
        },
      },
      // 멤버 관리
      {
        path: '/member',
        name: 'Member',
        component: () => import('@/views/group/Member.vue'),
        meta: {
          screenName: 'membermanagement_main',
          allowedMemberRoles: ['master', 'admin'],
          disallowGuestGroup: true,
        },
        props(route) {
          const pageSize = parseInt(route.query.pageSize as string)

          return {
            pageSize: pageSize || undefined,
          }
        },
      },

      // 팀 관리
      {
        path: '/group/team',
        name: 'GroupTeam',
        component: () => import('@/views/group/Team.vue'),
        meta: {
          allowedMemberRoles: ['master', 'admin'],
          disallowGuestGroup: true,
        },
      },

      // 크레딧 관리
      {
        path: '/group/credit',
        name: 'GroupCredit',
        component: () => import('@/views/group/Credit.vue'),
        meta: {
          allowedMemberRoles: ['master', 'admin'],
          disallowGuestGroup: true,
        },
      },

      // 크레딧 결제
      {
        path: '/group/credit-charge',
        name: 'GroupCreditCharge',
        component: () => import('@/views/group/CreditCharge.vue'),
        meta: {
          allowedMemberRoles: ['master', 'admin'],
          disallowGuestGroup: true,
        },
      },

      // 크레딧 결제 완료
      {
        path: '/group/credit-charge-done',
        name: 'GroupCreditChargeDone',
        component: () => import('@/views/group/CreditChargeDone.vue'),
        meta: {
          allowedMemberRoles: ['master', 'admin'],
          disallowGuestGroup: true,
          hideNagivationMenu: true,
        },
      },

      // 패파맛집 서브홈
      // {
      //   path: '/benefits/eats',
      //   name: 'BenefitEats',
      //   component: () => import('@/views/benefits/BenefitEats.vue'),
      //   meta: {
      //     screenName: 'eats_main',
      //     disallowGuestGroup: true,
      //   },
      // },

      // 베네핏 리스트 - 카테고리
      {
        path: '/benefits/category/:mainCategoryId?/:middleCategoryId?',
        name: 'BenefitListByCategory',
        component: () => import('@/views/benefits/BenefitListByCategory.vue'),
        meta: {
          usePathKey: true,
          disallowGuestGroup: true,
          aliasRouteNameForMenu: 'Benefits',
        },
        props(route) {
          const { params, query } = route
          const mainCategoryId = parseInt(params.mainCategoryId as string)
          const middleCategoryId = parseInt(params.middleCategoryId as string)
          const subCategoryId = parseInt(query.sc_id as string)

          return {
            mainCategoryId: mainCategoryId || undefined,
            middleCategoryId: middleCategoryId || undefined,
            subCategoryId: subCategoryId || undefined,
          }
        },
      },

      // 베네핏 리스트 - 큐레이션
      {
        path: '/benefits/curation/:curationId',
        name: 'BenefitCuration',
        component: () => import('@/views/benefits/BenefitCuration.vue'),
        meta: {
          usePathKey: true,
          disallowGuestGroup: true,
          aliasRouteNameForMenu: 'Benefits',
        },
        props(route) {
          const curationId = parseInt(route.params.curationId as string)

          return {
            curationId: curationId || undefined,
          }
        },
      },

      // 베네핏 리스트 - 추천
      {
        path: '/benefits/recommend',
        name: 'BenefitRecommend',
        component: () => import('@/views/benefits/BenefitRecommend.vue'),
        meta: {
          disallowGuestGroup: true,
          aliasRouteNameForMenu: 'Benefits',
        },
      },

      // 베네핏 검색
      {
        path: '/benefits/search',
        name: 'BenefitSearch',
        component: () => import('@/views/benefits/BenefitSearch.vue'),
        meta: {
          disallowGuestGroup: true,
        },
        props(route) {
          let text = route.query.text ?? ''
          const tab = Number(route.query.tab ?? 0)

          if (Array.isArray(text)) {
            text = text.join(' ')
          }

          return {
            text,
            tab,
          }
        },
      },

      // 베네핏 상세(패파맛집 포함)
      {
        path: '/benefits/:benefitId',
        name: 'BenefitDetail',
        component: () => import('@/views/benefits/BenefitDetail.vue'),
        meta: {
          screenName: 'benefits_main_detail_benefits',
          usePathKey: true,
          disallowGuestGroup: true,
        },
        props(route) {
          const benefitId = parseInt(route.params.benefitId as string)

          return {
            benefitId: benefitId || undefined,
          }
        },
      },

      // 베네핏 서브홈
      {
        path: '/benefits',
        name: 'Benefits',
        component: () => import('@/views/benefits/Benefits.vue'),
        meta: {
          screenName: 'benefits_main',
          disallowGuestGroup: true,
        },
      },

      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
          hideFooter: true,
        },
      },
    ],
  },

  // 404
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'NotFound',
    },
  },
]

export default routes
