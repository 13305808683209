<template>
  <div :class="$style.container">
    <div :class="$style.navContainer">
      <template
        v-for="(nav, navKey) in navs"
        :key="navKey"
      >
        <div
          v-if="navMenu.isVisiable(nav)"
          :class="$style.menu"
        >
          <div :class="$style.title">
            <component
              :class="$style.icon"
              :is="nav.componentName"
            />
            <span>{{ nav.title }}</span>
          </div>

          <template
            v-for="(child, childKey) in nav.childs"
            :key="childKey"
          >
            <template v-if="navMenu.isVisiable(child)">
              <router-link
                :to="{ name: child.routeName }"
                custom
                :active-class="$style.active"
                v-slot="{ href, navigate }"
              >
                <a
                  :href="href"
                  :class="{
                    [$style.item]: true,
                    [$style.active]: navMenu.isActive(child),
                  }"
                  @click.capture.prevent="
                    () => {
                      navigate()
                      onRouterLinkClick(child)
                    }
                  "
                  @mouseenter="() => setHoverRouteName(child)"
                  @mouseleave="() => removeHoverRouteName()"
                >
                  <span>{{ child.text }}</span>

                  <template v-if="child.badge">
                    <template v-if="child.badge.type">
                      <Badge
                        :class="$style.linkBadge"
                        color="f-gray-40"
                        hoverColor="f-primary-black"
                        activeColor="f-primary-black"
                        :active="navMenu.isActive(child) || isHover(child)"
                      >
                        <component
                          v-bind="child.badge.attrs"
                          :is="child.badge.name"
                        />
                      </Badge>
                    </template>
                  </template>
                </a>
              </router-link>
            </template>
          </template>
        </div>
      </template>
    </div>

    <div :class="$style.footer">
      <div :class="$style.item">
        <IconQuestion :class="$style.icon" /> 궁금한 점이 있으신가요?
      </div>
      <div :class="$style.item">
        <Button
          :class="$style.btn"
          size="large"
          theme="secondary-extension-orange"
          @click="onInquiryPage"
        >
          <img
            :class="$style.icon"
            :src="images.icon_request_image"
            alt="icon"
          />
          <span>1:1 문의하기</span>
        </Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DeepReadonly, computed, defineComponent, reactive } from 'vue'
import { useRouter } from 'vue-router'

import ImgBadgePlus from '@/components/svg/badge/ImgBadgePlus.vue'
import Badge from '@/components/v2/Badge.vue'
import Button from '@/components/v2/button/Button.vue'
import IconBenefit from '@/components/v2/icon/IconBenefit.vue'
import IconGroup from '@/components/v2/icon/IconGroup.vue'
import IconQuestion from '@/components/v2/icon/IconQuestion.vue'
import IconReservation from '@/components/v2/icon/IconReservation.vue'

import { useNavMenu } from '@/hooks/nav-menu'
import { NavMenu } from '@/interfaces/nav-menu'
import { CacheHelper } from '@/libs/storage/helpers/cache'
import { useKeepAliveStore } from '@/store/modules/keep-alive'

const images = {
  icon_request_image: require('@/assets/images/icon/icon_request_image.png'),
}

export default defineComponent({
  components: {
    Badge,
    Button,
    ImgBadgePlus,
    IconReservation,
    IconBenefit,
    IconGroup,
    IconQuestion,
  },
  emits: {
    'click:router-link': (val) => val && typeof val === 'string',
  },
  setup(props, { emit }) {
    const cacheHelper = new CacheHelper()

    const router = useRouter()
    const keepAliveStore = useKeepAliveStore()

    const navMenu = useNavMenu()

    const state = reactive({
      hoverRouteName: null as NavMenu.RouteName | null,
    })

    const navs = computed(() => navMenu.state.menu)

    function onRouterLinkClick(
      item: NavMenu.ChildItem | DeepReadonly<NavMenu.ChildItem>,
    ) {
      const routeName = item.routeName as string

      if (routeName) {
        keepAliveStore.delete(routeName)

        if (routeName === 'Reserve') {
          cacheHelper.removeItem(
            CacheHelper.definedKeys.RESERVE_PAGE_SPACE_CATEGORY_STATE,
          )
        }

        emit('click:router-link', routeName)
      }
    }

    function isHover(
      item: NavMenu.ChildItem | DeepReadonly<NavMenu.ChildItem>,
    ) {
      return state.hoverRouteName === item.routeName
    }

    function setHoverRouteName(
      item: NavMenu.ChildItem | DeepReadonly<NavMenu.ChildItem>,
    ) {
      state.hoverRouteName = item.routeName
    }

    function removeHoverRouteName() {
      state.hoverRouteName = null
    }

    async function onInquiryPage() {
      const routeName = 'CreateInquiry'
      keepAliveStore.delete(routeName)
      emit('click:router-link', routeName)
      await router.push({ name: routeName })
    }

    return {
      images,
      navMenu,
      navs,
      onRouterLinkClick,
      isHover,
      setHoverRouteName,
      removeHoverRouteName,
      onInquiryPage,
    }
  },
})
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: var(--side-width);
  height: 100%;
  z-index: 10;
  border-right: 1px solid $f-gray-20;

  .navContainer {
    padding: 24px 16px;

    .menu {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      .title {
        @include font_v2('ko', 18px, 700);
        display: flex;
        align-items: center;
        padding: 8px 16px;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 12px;
        }
      }

      .item {
        @include font_v2('ko', 16px, 400);

        display: flex;
        align-items: center;
        padding: 8px 0 8px 52px;
        color: $f-gray-40;
        text-decoration: none;
        cursor: pointer;
        border-radius: 12px;

        .linkBadge {
          margin-left: 8px;
        }

        &:hover {
          color: $f-primary-black;
          background-color: $f-gray-15;
        }

        &:active {
          color: $f-primary-black;
          background-color: $f-gray-15;
          font-weight: 500;
        }

        &.active {
          color: $f-primary-black;
          background-color: $f-gray-15;
          font-weight: 500;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer {
    margin-top: auto;
    padding: 0 32px 40px;

    .item {
      @include font_v2('ko', 12px, 400);

      margin-bottom: 12px;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 4px;
      }

      .btn {
        span {
          @include font_v2('ko', 15px, 500);
        }

        .icon {
          width: 24px;
        }
      }
    }
  }
}
</style>
