import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["type", "disabled", "placeholder", "required", "value", "maxlength", "min", "max"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.$style.formControl)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: _normalizeClass(_ctx.$style.label)
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: _normalizeClass(_ctx.$style.required)
              }, null, 2))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([_ctx.$style.container, { [_ctx.$style.disabled]: _ctx.isDisabled }])
    }, [
      _renderSlot(_ctx.$slots, "left"),
      _createElementVNode("input", {
        ref: "inputElement",
        class: _normalizeClass({ [_ctx.$style.input]: true, [_ctx.$style.enFont]: _ctx.isEnFont }),
        type: _ctx.type,
        disabled: _ctx.isDisabled,
        placeholder: _ctx.placeholder,
        required: _ctx.isRequired,
        value: _ctx.inputValue,
        maxlength: _ctx.maxlength,
        min: _ctx.type === 'number' ? _ctx.min : undefined,
        max: _ctx.type === 'number' ? _ctx.max : undefined,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFocusInput && _ctx.onFocusInput(...args))),
        onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onBlurInput && _ctx.onBlurInput(...args))),
        onKeydown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onKeyDown && _ctx.onKeyDown(...args)))
      }, null, 42, _hoisted_1),
      _renderSlot(_ctx.$slots, "right"),
      (!_ctx.isDisabled && _ctx.isDelete && _ctx.inputValue)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: _normalizeClass(_ctx.$style.deleteButton)
          }, [
            _createElementVNode("img", {
              src: _ctx.deleteIcon,
              alt: "삭제",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onResetButton && _ctx.onResetButton(...args)))
            }, null, 8, _hoisted_2)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.isCount && _ctx.maxlength)
        ? (_openBlock(), _createElementBlock("span", {
            key: 1,
            class: _normalizeClass(_ctx.$style.count)
          }, _toDisplayString(`${_ctx.inputValue ? _ctx.inputValue.length : 0}/${_ctx.maxlength}`), 3))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.warningText)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: _normalizeClass(_ctx.$style.warningText)
        }, [
          _createElementVNode("img", {
            src: _ctx.warningIcon,
            class: _normalizeClass(_ctx.$style.icon),
            alt: "경고"
          }, null, 10, _hoisted_3),
          _createTextVNode(" " + _toDisplayString(_ctx.warningText), 1)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.helperText)
      ? (_openBlock(), _createElementBlock("p", {
          key: 2,
          class: _normalizeClass(_ctx.$style.helperText)
        }, [
          _createElementVNode("img", {
            src: _ctx.helperIcon,
            class: _normalizeClass(_ctx.$style.icon),
            alt: "도움말"
          }, null, 10, _hoisted_4),
          _createTextVNode(" " + _toDisplayString(_ctx.helperText), 1)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}